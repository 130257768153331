import * as THREE from "three";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { toRaw } from "vue";
import API from "@/api/API";
import {
  MOUNTING_SURFACE_COLOR,
  RENDERING_ORDER,
  SOLAR_POINT_COLOR,
  MARGIN_COLOR,
  SELECTED_LINE_COLOR,
  DEFAULT_HORIZONTAL_SPACING,
  DEFAULT_VERTICAL_SPACING,
  DEFAULT_MARGIN,
  DEFAULT_OFFSET,
} from "../constants";
import { convertToCentimeters } from "../utils/units";
import { roundVector } from "../utils/round-vector.js";

export const hideAreas = function (hidePanels = true) {
  const filteredAreas = this.areas.filter((area) => area.plane);

  for (let i = 0; i < filteredAreas.length; i++) {
    this.hideSingleArea(filteredAreas[i], hidePanels);
  }
};
export const hideSingleArea = function (areaToHide, hidePanels) {
  if (areaToHide && areaToHide.plane) {
    areaToHide.plane.visible = false;
    if (areaToHide.moveGridLabel) areaToHide.moveGridLabel.visible = false;
    if (areaToHide.rotateGridLabel) areaToHide.rotateGridLabel.visible = false;
    areaToHide.points.forEach((point) => (point.visible = false));
    areaToHide.lines.forEach((line) => {
      line.line.visible = false;
      line.midPoint.visible = false;
    });

    if (areaToHide.innerPlane) {
      areaToHide.innerPlane.material.opacity = 0.0;
      areaToHide.innerPlane.visible = false;
    }

    if (hidePanels) {
      areaToHide.panels.forEach((panel) => (panel.plane.visible = false));
      if (areaToHide.type == "SOLAR_GROUP" && areaToHide.instancedMesh) {
        areaToHide.instancedMesh.visible = false;
      }
    }
  }
};

export const setPanelsOpaque = function () {
  this.setPanelsOpacity(1.0);
};

export const setPanelsTransparent = function () {
  this.setPanelsOpacity(0.5);
};

export const setPanelsOpacity = function (opacity) {
  this.areas
    .filter((area) => area.instancedMesh)
    .forEach((area) => {
      area.instancedMesh.material.opacity = opacity;
    });
};

export const showAreas = function () {
  const filteredAreas = this.areas.filter((area) => area.plane);

  for (let i = 0; i < filteredAreas.length; i++) {
    this.showSingleArea(filteredAreas[i]);
  }
};

export const showSingleArea = function (areaToShow) {
  if (areaToShow && areaToShow.plane) {
    areaToShow.plane.visible = true;
    if (areaToShow.innerPlane) areaToShow.innerPlane.visible = true;
    areaToShow.points.forEach((point) => (point.visible = true));
    areaToShow.lines.forEach((line) => {
      line.line.visible = true;
      line.midPoint.visible = true;
    });
    areaToShow.panels.forEach((panel) => (panel.plane.visible = true));
    if (areaToShow.type == "SOLAR_GROUP" && areaToShow.instancedMesh) {
      areaToShow.instancedMesh.visible = true;
    }
  }
};

export const removeUnfinshedArea = function () {
  const currentArea = this.areas[this.areas.length - 1];
  if (this.areas.length > 0 && !currentArea.closed) {
    const points = currentArea.points;
    this.removePoints(points);
    this.removeSnapIcon(false);

    if (this.measurementAreaEndingLine) {
      this.removeObjectFromScene(this.measurementAreaEndingLine);
      this.measurementAreaEndingLine = null;
    }
    this.removeDashedLine();

    if (currentArea.lines) {
      this.removeArrayFromScene(currentArea.lines.map((line) => line.line));
      currentArea.lines
        .map((line) => line.midPoint)
        .forEach((point) => {
          this.removeObjectWithChildrenFromScene(point);
        });
    }

    this.areas = this.areas.slice(0, this.areas.length - 1);
  }
};

export const updateTransparencyLevel = function (area, value) {
  area.panels.forEach((panel) => {
    const material = panel.plane.material;
    material.opacity = value / 100;
    material.needsUpdate = true;
  });
};

export const stickMousePointerToDot = function (event) {
  // disable point placing when clicking outside the model
  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  if (
    this.areas.length === 0 ||
    this.areas[this.areas.length - 1].points.length === 0 ||
    this.areas[this.areas.length - 1].closed
  )
    return;

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;
  let o = intersects[0];
  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const currentArea = this.areas[this.areas.length - 1];

  const firstDot = currentArea?.points[0];
  const secondDot = currentArea?.points[1];
  const thirdDot = currentArea?.points[2];
  const lastDot = currentArea?.points[currentArea.points.length - 1];

  const distance = firstDot.position.distanceTo(pIntersect);
  const threshold = 0.25;

  if (firstDot && secondDot && thirdDot) {
    if (distance <= threshold) {
      this.selectedPoint = firstDot;
      this.renderer.domElement.style.cursor = `none`;
      this.areas[this.areas.length - 1].closeArea = true;
      this.selectedPoint.setPointColor(MOUNTING_SURFACE_COLOR);
      this.dashedMeasurementLine.material.color.setHex(MOUNTING_SURFACE_COLOR);
      this.showSnapIcon();

      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = false;
      this.inMagenticField = true;

      const firstPoint = new THREE.Vector3();
      const secondPoint = new THREE.Vector3();
      firstDot.getWorldPosition(firstPoint);
      lastDot.getWorldPosition(secondPoint);
      let points = [firstPoint, secondPoint];
      this.updateLinePosition(this.dashedMeasurementLine, points);
    } else {
      if (this.detectAreaIntersection()) {
        this.renderer.domElement.style.cursor = `pointer`;
      } else {
        this.changeCursorToCrosshair();
      }
      this.areas[this.areas.length - 1].closeArea = false;
      if (this.selectedPoint) {
        this.selectedPoint.setPointColor(SOLAR_POINT_COLOR);
        this.dashedMeasurementLine.material.color.setHex(SOLAR_POINT_COLOR);
        if (this.measurementAreaEndingLine)
          this.measurementAreaEndingLine.visible = true;
        this.inMagenticField = false;
        this.hideSnapIcon();
        this.selectedPoint = null;
      }
    }
  } else {
    if (this.detectAreaIntersection()) {
      this.renderer.domElement.style.cursor = `pointer`;
    } else {
      this.changeCursorToCrosshair();
    }
    if (this.selectedPoint) {
      this.selectedPoint.setPointColor(SOLAR_POINT_COLOR);
      this.dashedMeasurementLine.material.color.setHex(SOLAR_POINT_COLOR);
      if (this.measurementAreaEndingLine)
        this.measurementAreaEndingLine.visible = true;
      this.inMagenticField = false;
      this.selectedPoint = null;
      this.hideSnapIcon();
    }
  }
};

export const addPoint = function (event) {
  if (this.restrictedAreaMode) return;
  event.preventDefault();

  if (this.disableClick(event)) return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) return;

  let shouldCloseArea = false;

  // check for closed area
  if (
    this.areas.length > 0 &&
    this.areas[this.areas.length - 1].points.length > 2 &&
    !this.areas[this.areas.length - 1].closed
  ) {
    let pointIntersects = this.raycaster.intersectObject(
      this.areas[this.areas.length - 1].points[0]
    );
    if (
      pointIntersects.length > 0 ||
      this.areas[this.areas.length - 1].closeArea
    ) {
      shouldCloseArea = true;
    }
  }

  // check for duplicate points
  if (
    this.areas.length > 0 &&
    this.areas[this.areas.length - 1].points.length > 0 &&
    !shouldCloseArea
  ) {
    let pointIntersects = this.raycaster.intersectObjects(
      this.areas[this.areas.length - 1].points
    );
    if (pointIntersects.length > 0) {
      if (this.areas[this.areas.length - 1].closed) this.selectArea(event);
      return;
    }
  }

  let solidLine = null;
  let midPoint = null;
  let oldTempLine = null;
  let oldFirstPoint = null;

  // replace dashed line with solid line
  if (this.areas.length > 0 && !this.areas[this.areas.length - 1].closed) {
    const lastArea = this.areas[this.areas.length - 1];
    oldFirstPoint = lastArea.firstPoint;
    if (lastArea.tempLine) {
      oldTempLine = lastArea.tempLine;
      const solidLinePoints = [
        lastArea.firstPoint.position,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0].position
          : lastArea.tempPoint.position,
      ];
      solidLine = this.createReactiveThickLine(
        solidLinePoints,
        4.0,
        false,
        false,
        SOLAR_POINT_COLOR
      );

      this.removeDashedLine();

      this.scene.add(solidLine);

      midPoint = this.createReactiveMidPoint(
        lastArea.firstPoint,
        this.checkForClosedArea(lastArea)
          ? lastArea.points[0]
          : lastArea.tempPoint,
        SOLAR_POINT_COLOR
      );
      this.scene.add(midPoint);

      lastArea.tempLine = null;
      if (lastArea.lines) {
        lastArea.lines.push({
          line: solidLine,
          firstPoint: lastArea.firstPoint,
          secondPoint: this.checkForClosedArea(lastArea)
            ? lastArea.points[0]
            : lastArea.tempPoint,
          midPoint: midPoint,
        });
      } else {
        lastArea.lines = [
          {
            line: solidLine,
            firstPoint: lastArea.firstPoint,
            secondPoint: this.checkForClosedArea(lastArea)
              ? lastArea.points[0]
              : lastArea.tempPoint,
            midPoint: midPoint,
          },
        ];
      }
    }
  }

  // check for closed area
  if (shouldCloseArea) {
    this.drawPlane(this.areas[this.areas.length - 1].points);
    return;
  }

  if (
    this.detectAreaIntersection() &&
    this.areas[this.areas.length - 1].closed
  ) {
    if (this.isAreaInFrontOfModel()) {
      this.selectArea(event);
      return;
    }
  }

  // disable marker adding/connecting when drag is on
  if (this.dragOn) return;

  let o = intersects[0];

  let pIntersect = o.point.clone();
  this.scene.worldToLocal(pIntersect);

  const dot = this.createReactivePoint(pIntersect, false, this.isFirstPoint);

  const tempPoint = this.createNonReactiveAreaPoint(
    pIntersect,
    SOLAR_POINT_COLOR
  );

  if (this.areas.length > 0 && !this.areas[this.areas.length - 1].closed) {
    this.areas[this.areas.length - 1].points.push(dot);
  } else {
    this.areas.push({ points: [dot], closed: false });
  }

  let area = this.areas[this.areas.length - 1];

  area.firstPoint = dot;
  area.tempPoint = tempPoint;

  if (area.lines && area.lines[area.lines.length - 1].firstPoint) {
    area.lines[area.lines.length - 1].secondPoint = dot;
  }
  this.camera.lookAt(area.firstPoint.position);

  const firstPoint = new THREE.Vector3();
  const secondPoint = new THREE.Vector3();
  area.firstPoint.getWorldPosition(firstPoint);
  area.tempPoint.getWorldPosition(secondPoint);
  let points = [firstPoint, secondPoint];

  let newLine = this.createReactiveThickLine(
    points,
    4.0,
    true,
    false,
    SOLAR_POINT_COLOR
  );
  this.dashedMeasurementLine = newLine;
  area.tempLine = newLine;
  this.scene.add(dot);

  if (area.points.length > 1)
    // add point undo callback function
    this.undoStack.push({
      action: "ADD_POINT",
      area: area,
      point: dot,
      line: solidLine,
      midPoint: midPoint,
      oldTempLine: oldTempLine,
      oldFirstPoint: oldFirstPoint,
      newTempLine: newLine,
    });
  this.resetRedoStack();
};

export const updatePreliminaryPointPositionForArea = function (event) {
  let intersects = [];
  if (
    this.areas.length > 0 &&
    !this.areas[this.areas.length - 1].closed &&
    !this.inMagenticField
  ) {
    this.setMousePosition(event);

    intersects = this.raycaster.intersectObject(this.modelObject.children[0]);

    if (intersects.length < 1) return;
    let o = intersects[0];
    let pIntersect = o.point.clone();
    this.scene.worldToLocal(pIntersect);
    const area = this.areas[this.areas.length - 1];
    let marker = area.tempPoint;
    marker.position.x = pIntersect.x;
    marker.position.y = pIntersect.y;
    marker.position.z = pIntersect.z;
    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    area.firstPoint.getWorldPosition(firstPoint);
    area.tempPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    if (this.dashedMeasurementLine) {
      this.updateLinePosition(this.dashedMeasurementLine, points);
    }

    const line = this.dashedMeasurementLine;
    const lineObject = this.scene.getObjectById(line.id);
    if (!lineObject) this.scene.add(toRaw(line));

    const numPoints = area.points.length;
    if (numPoints > 2) {
      const areaFirstDot = new THREE.Vector3();
      area.points[0].getWorldPosition(areaFirstDot);
      const endingPoints = [areaFirstDot, secondPoint];

      if (this.measurementAreaEndingLine) {
        this.updateLinePosition(this.measurementAreaEndingLine, endingPoints);
      } else {
        let newDottedLine = this.createReactiveThickLine(
          endingPoints,
          4.0,
          true,
          true,
          SOLAR_POINT_COLOR
        );
        this.measurementAreaEndingLine = newDottedLine;
        this.scene.add(newDottedLine);
      }
    }
  }
  return intersects;
};

export const selectArea = function (event) {
  event.preventDefault();

  if (event.target.tagName !== "CANVAS") return;

  this.setMousePosition(event);

  const solarObjects = [];
  const filteredAreas = this.areas.filter((area) => area.plane);
  for (let area of filteredAreas) {
    solarObjects.push(area.plane);
    solarObjects.push(...area.points);
  }
  let intersects = this.raycaster.intersectObjects(solarObjects);
  if (intersects.length < 1) return;

  let areaToExpand = intersects[0].object;
  let clickedArea = this.areas.find(
    (area) => area.plane && area.plane.id === areaToExpand.id
  );
  if (!clickedArea) {
    clickedArea = this.areas.find((area) => {
      return area.points.find((point) => point.id === areaToExpand.id);
    });
  }
  if (this.existingAreasIds.includes(clickedArea.id) && this.anonymousUser) {
    return;
  }
  this.previousSolarArea = this.selectedArea;

  if (this.selectedArea && this.selectedArea.plane.id === clickedArea.plane.id)
    return;

  const areaAlreadySelected = this.selectedArea ? true : false;

  clickedArea.expanded = true;
  this.setLinesColorForSelectedSolarGroup(clickedArea);
  this.setPointsColorForSelectedSolarGroup(clickedArea);
  this.setMidPointsColorForSelectedSolarGroup(clickedArea);
  if (clickedArea.innerPlane) clickedArea.innerPlane.material.opacity = 0.25;

  if (clickedArea.moveGridLabel && clickedArea.rotateGridLabel) {
    clickedArea.moveGridLabel.visible = true;
    clickedArea.rotateGridLabel.visible = true;
  }

  this.areas
    .filter((area) => area.plane.id !== clickedArea.plane.id)
    .forEach((area) => {
      clickedArea.expanded = false;
      if (area.innerPlane) area.innerPlane.material.opacity = 0;
      if (area.moveGridLabel) area.moveGridLabel.visible = false;
      if (area.rotateGridLabel) area.rotateGridLabel.visible = false;
      this.setLinesColorForSelectedSolarGroup(area, false);
      this.setPointsColorForSelectedSolarGroup(area, false);
      this.setMidPointsColorForSelectedSolarGroup(area, false);
      this.hideRestrictedAreaDetails(area);
    });

  if (areaAlreadySelected) this.disablePointDragModeForRestrictedAreas();

  this.selectedArea = clickedArea;

  if (!areaAlreadySelected) {
    document.removeEventListener("click", this.addPoint, false);
    window.removeEventListener("mousemove", this.stickMousePointerToDot);

    document.removeEventListener(
      "mousemove",
      this.detectModelIntersection,
      false
    );

    document.removeEventListener("keydown", this.removeLastPoint, false);
    document.addEventListener(
      "mousemove",
      this.detectSolarAreaPointsIntersection,
      false
    );
    document.addEventListener("click", this.selectSolarArea, false);

    window.addEventListener("wheel", this.controlSpacingBetweenLabels, false);
  }

  this.showRestrictedAreaDetails(this.selectedArea);

  this.enablePointDragMode();
  this.enablePointDragModeForRestrictedAreas();

  this.checkPanelNumberAndVisibility(this.selectedArea);
  this.controlSpacingBetweenLabels();
};

export const selectAreaWithoutClick = function (area) {
  area.expanded = true;
  this.setLinesColorForSelectedSolarGroup(area);
  this.setPointsColorForSelectedSolarGroup(area);
  this.setMidPointsColorForSelectedSolarGroup(area);
  if (area.innerPlane) area.innerPlane.material.opacity = 0.25;

  this.selectedArea = area;

  document.removeEventListener("click", this.addPoint, false);
  window.removeEventListener("mousemove", this.stickMousePointerToDot);

  document.removeEventListener(
    "mousemove",
    this.detectModelIntersection,
    false
  );

  document.removeEventListener("keydown", this.removeLastPoint, false);

  document.addEventListener(
    "mousemove",
    this.detectSolarAreaPointsIntersection,
    false
  );
  document.addEventListener("click", this.selectSolarArea, false);

  window.addEventListener("wheel", this.controlSpacingBetweenLabels, false);

  this.showRestrictedAreaDetails(this.selectedArea);

  this.enablePointDragMode();
  this.enablePointDragModeForRestrictedAreas();
};

export const selectSolarArea = function (event) {
  event.preventDefault();

  if (this.disableClick(event)) return;

  this.setMousePosition(event);

  let intersects = this.raycaster.intersectObject(this.modelObject.children[0]);
  if (intersects.length < 1) this.unselectSolarArea();

  if (this.detectAreaIntersection()) {
    this.selectArea(event);
  } else {
    this.unselectSolarArea();
  }
};

export const deselectSolarArea = function () {
  if (this.selectedArea) {
    this.hideRestrictedAreaDetails(this.selectedArea);
    this.selectedArea.expanded = false;
    this.setLinesColorForSelectedSolarGroup(this.selectedArea, false);
    this.setPointsColorForSelectedSolarGroup(this.selectedArea, false);
    this.setMidPointsColorForSelectedSolarGroup(this.selectedArea, false);
    if (this.selectedArea.innerPlane)
      this.selectedArea.innerPlane.material.opacity = 0.0;
    if (this.selectedArea.moveGridLabel)
      this.selectedArea.moveGridLabel.visible = false;
    if (this.selectedArea.rotateGridLabel)
      this.selectedArea.rotateGridLabel.visible = false;

    this.selectedArea = null;
  }
};

export const unselectSolarArea = function () {
  this.disablePointDragMode();
  this.disablePointDragModeForRestrictedAreas();
  this.deselectSolarArea();

  document.removeEventListener("click", this.selectSolarArea, false);
  document.removeEventListener(
    "mousemove",
    this.detectSolarAreaPointsIntersection,
    false
  );

  window.removeEventListener("mousedown", this.onDragSolarGroupStart);
  window.removeEventListener("mousemove", this.onDragSolarGroup);
  window.removeEventListener("mouseup", this.onDragSolarGroupEnd);

  document.addEventListener("click", this.addPoint, false);
  window.addEventListener("mousemove", this.stickMousePointerToDot);

  document.addEventListener("mousemove", this.detectModelIntersection, false);

  document.addEventListener("keydown", this.removeLastPoint, false);

  window.removeEventListener("wheel", this.controlSpacingBetweenLabels, false);
};

export const holdSolarArea = function (area) {
  if (area) {
    area.innerPlane.material.opacity = 0.0;
    this.disableDefaultNavigation();
    document.removeEventListener("click", this.selectSolarArea, false);
    document.removeEventListener(
      "mousemove",
      this.detectSolarAreaPointsIntersection,
      false
    );

    document.removeEventListener("click", this.addPoint, false);
    window.removeEventListener("mousemove", this.stickMousePointerToDot);

    document.removeEventListener(
      "mousemove",
      this.detectModelIntersection,
      false
    );

    document.removeEventListener("keydown", this.removeLastPoint, false);
  }
};

export const unHoldSolarArea = function (area) {
  if (area) {
    if (this.selectedArea) {
      this.setLinesColorForSelectedSolarGroup(this.selectedArea, false);
      this.setPointsColorForSelectedSolarGroup(this.selectedArea, false);
      this.setMidPointsColorForSelectedSolarGroup(this.selectedArea, false);
    }

    this.selectedArea = area;
    setTimeout(() => {
      this.selectAreaWithoutClick(area);
    }, 0);
  }
};

export const setLinesColorForSelectedSolarGroup = function (
  area,
  selected = true
) {
  if (area) {
    area.lines.forEach((line) => {
      line.line.material.color.setHex(
        selected ? SELECTED_LINE_COLOR : SOLAR_POINT_COLOR
      );
    });
  }
};

export const setPointsColorForSelectedSolarGroup = function (
  area,
  selected = true
) {
  if (area) {
    area.points.forEach((point) => {
      point.setPointColor(selected ? SELECTED_LINE_COLOR : SOLAR_POINT_COLOR);
    });
  }
};

export const setMidPointsColorForSelectedSolarGroup = function (
  area,
  selected = true
) {
  if (area) {
    area.lines.forEach((line) => {
      line.midPoint.setPointColor(
        selected ? SELECTED_LINE_COLOR : SOLAR_POINT_COLOR
      );
    });
  }
};

export const onDragSolarGroupStart = function (event) {
  this.dragOn = true;

  const areaPlane = this.selectedArea.trianglePlane;

  this.setLabelOpacity(this.selectedArea.moveGridLabel, 1.0);
  this.setLabelOpacity(this.selectedArea.rotateGridLabel, 0.0);

  this.selectedArea.instancedMesh.material.opacity = 0.75;

  this.disablePointerEventsOnLabel(this.selectedArea.rotateGridLabel);

  const originalPosition = new THREE.Vector3(
    this.selectedArea.moveGridLabel.position.x,
    this.selectedArea.moveGridLabel.position.y,
    this.selectedArea.moveGridLabel.position.z
  );

  const projectedOriginalPosition = new THREE.Vector3();
  areaPlane.projectPoint(originalPosition, projectedOriginalPosition);

  this.selectedArea.moveGridLabel.originalPosition = {
    x: projectedOriginalPosition.x,
    y: projectedOriginalPosition.y,
    z: projectedOriginalPosition.z,
  };

  this.checkPanelNumberAndVisibility(this.selectedArea);

  document.addEventListener("mousemove", this.onDragSolarGroup);
  document.addEventListener("mouseup", this.onDragSolarGroupEnd);
};

export const onDragSolarGroup = function (event) {
  if (!this.dragOn) return;

  this.setMousePosition(event);

  const intersectionPoint = new THREE.Vector3();
  const hasIntersection = this.raycaster.ray.intersectPlane(
    this.selectedArea.infinitePlane,
    intersectionPoint
  );

  if (!hasIntersection) {
    this.dragOn = false;
    return;
  }

  const areaPlane = this.selectedArea.trianglePlane;

  const projectedPoint = new THREE.Vector3();
  areaPlane.projectPoint(intersectionPoint, projectedPoint);

  const translationVector = new THREE.Vector3();
  translationVector.subVectors(
    projectedPoint,
    new THREE.Vector3(
      this.selectedArea.moveGridLabel.originalPosition.x,
      this.selectedArea.moveGridLabel.originalPosition.y,
      this.selectedArea.moveGridLabel.originalPosition.z
    )
  );

  const { instancedMesh, normal } = this.selectedArea;
  const tempPosition = new THREE.Vector3();
  const transformationMatrix = new THREE.Matrix4();
  const offset = normal.clone().multiplyScalar(this.selectedArea.offset / 100);

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);

    transformationMatrix.decompose(
      tempPosition,
      new THREE.Quaternion(),
      new THREE.Vector3()
    );

    tempPosition.add(translationVector);
    transformationMatrix.setPosition(tempPosition);

    instancedMesh.setMatrixAt(i, transformationMatrix.clone());
  }

  instancedMesh.seed.add(translationVector);
  instancedMesh.instanceMatrix.needsUpdate = true;

  this.selectedArea.moveGridLabel.position.copy(projectedPoint);

  this.selectedArea.moveGridLabel.originalPosition = {
    x: projectedPoint.x,
    y: projectedPoint.y,
    z: projectedPoint.z,
  };
};

export const onDragSolarGroupEnd = function (event) {
  this.dragOn = false;
  this.selectAreaWithoutClick(this.selectedArea);
  this.checkPanelsInSolarArea(this.selectedArea);

  this.setTranslationVectorForSolarGroup();
  this.checkAndPopulateEmptyArea(this.selectedArea);

  document.removeEventListener("mousemove", this.onDragSolarGroup);
  document.removeEventListener("mouseup", this.onDragSolarGroupEnd);
};

export const setTranslationVectorForSolarGroup = function () {
  const finalPosition = this.selectedArea.instancedMesh.seed;

  const offset = this.selectedArea.normal
    .clone()
    .multiplyScalar(this.selectedArea.offset / 100);

  const finalPositionNoOffset = finalPosition.clone();
  finalPositionNoOffset.add(offset);

  this.selectedArea.startPosition = finalPositionNoOffset;

  const moveGridLabel = this.selectedArea.moveGridLabel;
  const rotateGridLabel = this.selectedArea.rotateGridLabel;

  this.setLabelOpacity(moveGridLabel, 0.7);
  this.setLabelOpacity(rotateGridLabel, 0.7);
  this.enablePointerEventsOnLabel(this.selectedArea.rotateGridLabel);

  const centerPoint = this.getCenterPointFromVectors(
    this.selectedArea.innerPlane.points
  );

  const moveLabelPosition = new THREE.Vector3()
    .copy(centerPoint)
    .add(moveGridLabel.labelOffset);
  moveGridLabel.position.set(
    moveLabelPosition.x,
    moveLabelPosition.y,
    moveLabelPosition.z
  );

  const rotateLabelPosition = new THREE.Vector3()
    .copy(moveGridLabel.position)
    .add(rotateGridLabel.labelOffset)
    .add(rotateGridLabel.labelOffset);
  rotateGridLabel.position.set(
    rotateLabelPosition.x,
    rotateLabelPosition.y,
    rotateLabelPosition.z
  );

  this.selectedArea.instancedMesh.material.opacity = 0.5;

  this.createUpdateSolarGroup(this.selectedArea);
};

export const checkAndPopulateEmptyArea = function (area) {
  if (
    area.instancedMesh.visibilityArray.some((visibility) => visibility === true)
  )
    return;

  this.removeSolarGroupPanels(area);
  this.populateArea(area);
};

export const setLabelOpacity = function (label, opacity) {
  label.element.style.opacity = opacity;
};

export const enablePointerEventsOnLabel = function (label) {
  label.element.style.pointerEvents = "all";
};

export const disablePointerEventsOnLabel = function (label) {
  label.element.style.pointerEvents = "none";
};

export const onRotateSolarGroupStart = function (event) {
  this.dragOn = true;

  this.initialMouseX = event.clientX;

  if (!this.selectedArea.currentRotation) {
    this.selectedArea.currentRotation = 0;
  }

  if (!this.selectedArea.areaCenter) {
    this.selectedArea.areaCenter = this.getCenterPointFromVectors(
      this.selectedArea.innerPlane.points
    );
  }

  this.setLabelOpacity(this.selectedArea.moveGridLabel, 0.0);
  this.setLabelOpacity(this.selectedArea.rotateGridLabel, 1.0);

  this.disablePointerEventsOnLabel(this.selectedArea.moveGridLabel);

  this.selectedArea.instancedMesh.material.opacity = 0.75;

  document.addEventListener("mousemove", this.onRotateSolarGroup);
  document.addEventListener("mouseup", this.onRotateSolarGroupEnd);
};

export const onRotateSolarGroup = function (event) {
  if (!this.dragOn) return;

  const deltaX = event.clientX - this.initialMouseX;
  const rotationAngle = deltaX * this.rotationSpeed;

  this.initialMouseX = event.clientX;

  const accumulatedRotation = this.selectedArea.currentRotation + rotationAngle;

  if (!this.isAngleInRange(accumulatedRotation)) return;
  this.selectedArea.currentRotation = accumulatedRotation;

  const { normal, instancedMesh, areaCenter } = this.selectedArea;

  const transformationMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3(1, 1, 1);

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    tempPosition.sub(areaCenter);

    const rotationMatrix = new THREE.Matrix4().makeRotationAxis(
      normal,
      rotationAngle
    );
    tempPosition.applyMatrix4(rotationMatrix);

    const newQuaternion = new THREE.Quaternion().setFromRotationMatrix(
      rotationMatrix
    );

    tempQuaternion.premultiply(newQuaternion);

    tempPosition.add(areaCenter);

    transformationMatrix.compose(tempPosition, tempQuaternion, tempScale);
    instancedMesh.setMatrixAt(i, transformationMatrix);
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const onRotateSolarGroupEnd = function (event) {
  this.dragOn = false;

  this.checkPanelsInSolarArea(this.selectedArea);

  this.setLabelOpacity(this.selectedArea.moveGridLabel, 0.7);
  this.setLabelOpacity(this.selectedArea.rotateGridLabel, 0.7);

  this.enablePointerEventsOnLabel(this.selectedArea.moveGridLabel);

  this.selectedArea.instancedMesh.material.opacity = 0.5;

  this.checkPanelNumberAndVisibility(this.selectedArea);

  document.removeEventListener("mousemove", this.onRotateSolarGroup);
  document.removeEventListener("mouseup", this.onRotateSolarGroupEnd);

  this.createUpdateSolarGroup(this.selectedArea);
};

export const updatePanelVisibility = function (selectedArea) {
  const { normal, instancedMesh, offset, testPanel, simulatedCamera, ground } =
    selectedArea;

  const transformationMatrix = new THREE.Matrix4();
  const tempPosition = new THREE.Vector3();
  const tempQuaternion = new THREE.Quaternion();
  const tempScale = new THREE.Vector3(1, 1, 1);

  for (let i = 0; i < instancedMesh.instancesCount; i++) {
    if (!instancedMesh.visibilityArray[i]) continue;

    instancedMesh.getMatrixAt(i, transformationMatrix);
    transformationMatrix.decompose(tempPosition, tempQuaternion, tempScale);

    const tempPositionWithoutOffset = tempPosition.clone();
    const meterOffset = normal.clone().multiplyScalar(offset / 100);
    tempPositionWithoutOffset.add(meterOffset);

    if (
      !this.panelInsideArea(
        tempPositionWithoutOffset,
        tempQuaternion,
        testPanel,
        ground,
        normal,
        simulatedCamera,
        selectedArea.innerPlane
      )
    ) {
      instancedMesh.setVisibilityAt(i, false);
    }
  }

  instancedMesh.instanceMatrix.needsUpdate = true;
};

export const hideAreaPoints = function (area) {
  area.points.forEach((point) => (point.visible = false));
  area.lines.forEach((line) => {
    line.midPoint.visible = false;
    line.line.visible = false;
  });
  document.removeEventListener("click", this.addPoint, false);
};

export const panelInsideArea = function (
  position,
  rotation,
  testPanel,
  ground,
  normal,
  simulatedCamera,
  innerPlane
) {
  const { panelVertices, panelEdges } = this.createPanelAt({
    panel: testPanel,
    pos: position,
    rotation,
    ground,
    normal,
  });

  if (
    !this.isRectangleWithinPoints(
      panelVertices,
      panelEdges,
      innerPlane.points,
      innerPlane.edges,
      simulatedCamera
    )
  ) {
    return false;
  }
  return true;
};

export const resetPointsColor = function () {
  this.areas[this.areas.length - 1].points.forEach((point) =>
    point.setPointColor(SOLAR_POINT_COLOR)
  );
};

export const createPlaneForSolarArea = function (points) {
  let triangle = new THREE.Triangle(points[0], points[1], points[2]);

  let plane = new THREE.Plane();
  triangle.getPlane(plane);

  return plane;
};

export const projectPointsOnPlane = function (points, plane) {
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const projectedPoints = [];
  for (let i = 0; i < vectorPoints.length; i++) {
    const projectedPoint = new THREE.Vector3();
    plane.projectPoint(vectorPoints[i], projectedPoint);
    projectedPoints.push(projectedPoint);

    points[i].position.x = projectedPoint.x;
    points[i].position.y = projectedPoint.y;
    points[i].position.z = projectedPoint.z;
  }

  const projectedPointsAsArray = projectedPoints.map((point) => [
    point.x,
    point.y,
    point.z,
  ]);

  const flatProjectedPoints = [].concat(...projectedPointsAsArray);

  return flatProjectedPoints;
};

export const projectLinesOnPlane = function (lines, plane) {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    line.firstPoint.getWorldPosition(firstPoint);
    line.secondPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];

    this.updateLinePosition(line.line, points);
    if (line.midPoint) {
      this.updateMidPointPosition(line.midPoint, firstPoint, secondPoint);
    }
  }
};

export const drawPlane = async function (points, createArea = true) {
  this.resetPointsColor();
  this.resetUndoStack();
  this.resetRedoStack();
  this.removeSnapIcon();

  this.removeDashedLine();

  if (this.measurementAreaEndingLine)
    this.removeObjectFromScene(this.measurementAreaEndingLine);

  this.measurementAreaEndingLine = null;

  // Convert points to Vector3 objects
  const vectorPoints = points.map(
    (point) =>
      new THREE.Vector3(point.position.x, point.position.y, point.position.z)
  );

  const areaPlane = this.createPlaneForSolarArea(vectorPoints);

  const projectedPoints = this.projectPointsOnPlane(points, areaPlane);

  const area = this.areas[this.areas.length - 1];
  this.projectLinesOnPlane(area.lines, areaPlane);
  const geometry = new THREE.BufferGeometry();

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    points,
    this.getAxisDifferences(points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
  geometry.setIndex(new THREE.Uint16BufferAttribute(indices, 1));

  const material = new THREE.MeshBasicMaterial({
    color: MARGIN_COLOR,
    side: THREE.DoubleSide,
    stencilWrite: true,
    stencilFunc: THREE.NeverStencilFunc,
    stencilRef: this.stencilCount,
    stencilZPass: THREE.ReplaceStencilOp,
    transparent: true,
    opacity: 0.0,
  });

  const plane = new THREE.Mesh(geometry, material);

  plane.material.depthTest = false;
  plane.renderOrder = RENDERING_ORDER.SOLAR_PLANE;

  if (createArea) {
    if (this.solarDefaultValues) {
      this.areas[this.areas.length - 1] = {
        ...this.areas[this.areas.length - 1],
        ...this.solarDefaultValues,
      };
    } else {
      this.useDefaultSolarValues(this.areas[this.areas.length - 1]);
    }
    this.areas[this.areas.length - 1].type = "SOLAR_GROUP";
  }
  this.areas[this.areas.length - 1].plane = plane;
  this.areas[this.areas.length - 1].expanded = false;
  this.areas[this.areas.length - 1].transparencyLevel = 100;
  this.areas[this.areas.length - 1].panelType = null;
  this.areas[this.areas.length - 1].panels = [];
  this.areas[this.areas.length - 1].restrictedAreas =
    this.areas[this.areas.length - 1].restrictedAreas || [];
  this.areas[this.areas.length - 1].closed = true;
  this.areas[this.areas.length - 1].closeArea = false;
  this.areas[this.areas.length - 1].trianglePlane = areaPlane;
  this.areas[this.areas.length - 1].indices = indices;
  this.areas[this.areas.length - 1].stencilCount = this.stencilCount;
  this.areas[this.areas.length - 1].angle = this.calculateAreaAngle(
    geometry,
    material
  );

  this.scene.add(plane);
  this.stencilCount++;

  if (createArea) {
    this.selectAreaWithoutClick(this.areas[this.areas.length - 1]);
    this.populateArea(this.selectedArea);
    this.checkPanelNumberAndVisibility(this.selectedArea);
    // removed due to mk3 split release
    // this.solarGroupActivate();
    return;
  }
  if (this.areas[this.areas.length - 1].type === "SOLAR_GROUP")
    this.populateArea(this.areas[this.areas.length - 1], false);
};

export const createAreaObject = async function (points) {
  if (this.sample) return;
  const areaObject = {
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.createAreaObject(areaObject);
};

export const updateAreaObject = async function (id, points) {
  if (this.sample || this.anonymousUser) return;
  const areaObject = {
    id,
    projectId: Number(this.projectId),
    position: points,
  };
  return await API.airteam3DViewer.updateAreaObject(areaObject);
};

export const deleteAreaObject = async function (id) {
  if (this.sample) return;
  return await API.airteam3DViewer.deleteObject(id);
};

export const removeSolarGroup = async function (solarGroup) {
  const solarGroupPlane = this.scene.getObjectById(solarGroup.plane.id);
  for (let point of solarGroup.points) {
    const pointObject = this.scene.getObjectById(point.id);
    this.scene.remove(pointObject);
  }
  for (let line of solarGroup.lines) {
    const lineObject = this.scene.getObjectById(line.line.id);
    const midPointObject = this.scene.getObjectById(line.midPoint.id);
    this.scene.remove(lineObject);
    this.scene.remove(midPointObject);
  }

  this.scene.remove(solarGroupPlane);
  this.removeSolarGroupPanels(solarGroup);

  this.areas = this.areas.filter(
    (area) => !area.plane || area.plane.id !== solarGroup.plane.id
  );
  this.removeAllRestrictedAreas(solarGroup);

  const moveGridLabel = this.scene.getObjectById(solarGroup.moveGridLabel.id);
  const rotateGridLabel = this.scene.getObjectById(
    solarGroup.rotateGridLabel.id
  );
  if (moveGridLabel) this.scene.remove(moveGridLabel);
  if (rotateGridLabel) this.scene.remove(rotateGridLabel);

  return await this.deleteAreaObject(solarGroup.id);
};

export const removeSelectedArea = function (areaToRemove) {
  if (areaToRemove.type === "SOLAR_GROUP") {
    this.unselectSolarArea();
    return this.removeSolarGroup(areaToRemove);
  }
  const areaObject = this.scene.getObjectById(areaToRemove.plane.id);
  for (let panel of areaToRemove.panels) {
    const panelObject = this.scene.getObjectById(panel.plane.id);
    panelObject.visible = false;
  }
  for (let restrictedArea of areaToRemove.restrictedAreas) {
    const restrictedAreaObject = this.scene.getObjectById(
      restrictedArea.plane.id
    );
    restrictedAreaObject.visible = false;

    for (let point of restrictedArea.points) {
      const pointObject = this.scene.getObjectById(point.id);
      pointObject.visible = false;
    }
    for (let line of restrictedArea.lines) {
      const lineObject = this.scene.getObjectById(line.line.id);
      lineObject.visible = false;
    }
  }
  for (let point of areaToRemove.points) {
    const pointObject = this.scene.getObjectById(point.id);
    pointObject.visible = false;
  }
  for (let line of areaToRemove.lines) {
    const lineObject = this.scene.getObjectById(line.line.id);
    const midPointObject = this.scene.getObjectById(line.midPoint.id);
    lineObject.visible = false;
    midPointObject.visible = false;
  }
  this.areas = this.areas.filter(
    (area) => !area.plane || area.plane.id !== areaToRemove.plane.id
  );
  areaObject.visible = false;

  this.deleteAreaObject(areaToRemove.id);

  this.undoStack.push({ action: "DELETE_AREA", area: areaToRemove });
  this.resetRedoStack();
};

export const dragStarted = function (e) {
  const draggedPoint = e.object;
  draggedPoint.lastPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };
  draggedPoint.originalPosition = {
    x: draggedPoint.position.x,
    y: draggedPoint.position.y,
    z: draggedPoint.position.z,
  };

  this.holdSolarArea(this.selectedArea);

  if (this.selectedArea.moveGridLabel) {
    this.selectedArea.moveGridLabel.visible = false;
    this.selectedArea.rotateGridLabel.visible = false;
  }
};

export const dragEnded = function (e) {
  const draggedPoint = e.object;

  const cameraPosition = this.camera.position.clone();
  const pointPosition = draggedPoint.position.clone();
  const rayDirection = pointPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);
  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length < 1) {
    draggedPoint.position.x = draggedPoint.lastPosition.x;
    draggedPoint.position.y = draggedPoint.lastPosition.y;
    draggedPoint.position.z = draggedPoint.lastPosition.z;

    this.reDrawAreaFromPoint(e);
  }
  let area = this.getAreaFromPoint(draggedPoint);

  if (this.pointBelongsToArea(draggedPoint, area))
    this.undoStack.push({
      action: "MOVE_POINT",
      areaType: "MOUNTING",
      area: area,
      point: draggedPoint,
    });

  if (this.selectedArea.restrictedAreas) {
    this.selectedArea.restrictedAreas.forEach((restrictedArea) => {
      let outsideRA = [];

      if (
        !isRestrictedAreaContained(
          this.selectedArea.plane,
          restrictedArea.plane
        )
      ) {
        outsideRA.push(restrictedArea);
      }

      // Remove any restricted areas that are outside
      if (outsideRA.length > 0) {
        outsideRA.forEach((restrictedArea) => {
          this.removeRestrictedArea(restrictedArea);
        });
      }
    });
  }
  this.resetUndoStack();
  this.resetRedoStack();

  this.replaceSolarGroupInnerPlane(area);
  this.checkPanelsInSolarArea(area);

  const currentPlacementPanelsCount = area.instancedMesh.visibilityArray.filter(
    (v) => v === true
  ).length;

  const newPlacementPanelsCount = this.simulatePopulateArea(area);

  if (newPlacementPanelsCount > currentPlacementPanelsCount) {
    this.removeSolarGroupPanels(area);
    this.populateArea(area, true, false, true);
  } else {
    this.createUpdateSolarGroup(area);
  }

  this.unHoldSolarArea(area);

  if (this.selectedArea.moveGridLabel) {
    this.selectedArea.moveGridLabel.visible = true;
    this.selectedArea.rotateGridLabel.visible = true;
  }
  this.checkPanelNumberAndVisibility(this.selectedArea);
};

export const dragMidPointEnded = function (e) {
  if (!this.selectedArea.midPointAdded) {
    this.unHoldSolarArea(this.selectedArea);
    if (this.selectedArea.moveGridLabel) {
      this.selectedArea.moveGridLabel.visible = true;
      this.selectedArea.rotateGridLabel.visible = true;
    }
    return;
  }

  this.selectedArea.midPointAdded = false;

  this.selectedArea.lines.splice(this.selectedArea.lineIndexToRemove, 1);
  const draggedPoint = e.object;

  const cameraPosition = this.camera.position.clone();
  const pointPosition = draggedPoint.position.clone();
  const rayDirection = pointPosition.sub(cameraPosition).normalize();

  this.raycaster.set(cameraPosition, rayDirection);

  const intersects = this.raycaster.intersectObject(
    this.modelObject.children[0]
  );

  if (intersects.length < 1) {
    draggedPoint.position.x = draggedPoint.prevPosition.x;
    draggedPoint.position.y = draggedPoint.prevPosition.y;
    draggedPoint.position.z = draggedPoint.prevPosition.z;

    this.reDrawAreaFromPoint(draggedPoint);
  }

  const sceneObject = this.scene.getObjectById(draggedPoint.id);
  this.scene.remove(sceneObject.parent);

  const newPoint = this.createReactivePoint(draggedPoint.position, false);
  this.scene.add(newPoint);

  for (let i = 0; i < this.selectedArea.points.length; i++) {
    const point = this.selectedArea.points[i];
    if (point.uuid === draggedPoint.uuid) {
      this.selectedArea.points[i] = newPoint;
    }
  }
  for (let line of this.selectedArea.lines) {
    if (line.firstPoint.uuid === draggedPoint.uuid) line.firstPoint = newPoint;
    if (line.secondPoint.uuid === draggedPoint.uuid)
      line.secondPoint = newPoint;
  }

  this.reDrawAreaFromPoint(this.selectedArea.points[0]);

  this.disablePointDragMode();
  this.enablePointDragMode();
  document.removeEventListener(
    "mousemove",
    this.detectModelIntersection,
    false
  );

  // Save state for undo
  // const newLines = this.currentArea.lines.filter(
  //   (line) =>
  //     line.firstPoint.id === newPoint.id || line.secondPoint.id === newPoint.id
  // );
  // this.undoStack.push({
  //   action: "MOVE_MID_POINT",
  //   area: this.currentArea,
  //   removedLine: removedLine,
  //   newLine1: newLines[0],
  //   newLine2: newLines[1],
  //   newPoint: newPoint,
  // });

  if (this.selectedArea.restrictedAreas) {
    this.selectedArea.restrictedAreas.forEach((restrictedArea) => {
      let outsideRA = [];

      if (
        !isRestrictedAreaContained(
          this.selectedArea.plane,
          restrictedArea.plane
        )
      ) {
        outsideRA.push(restrictedArea);
      }

      // Remove any restricted areas that are outside
      if (outsideRA.length > 0) {
        outsideRA.forEach((restrictedArea) => {
          this.removeRestrictedArea(restrictedArea);
        });
      }
    });
  }
  this.resetUndoStack();
  this.resetRedoStack();

  this.removeSolarGroupPanels(this.selectedArea);
  this.populateArea(this.selectedArea);
  this.unHoldSolarArea(this.selectedArea);

  if (this.selectedArea.moveGridLabel) {
    this.selectedArea.moveGridLabel.visible = true;
    this.selectedArea.rotateGridLabel.visible = true;
  }
};

export const enablePointDragMode = function () {
  if (!this.selectedArea) return;
  if (this.selectedArea.panels.length > 0) return;
  if (this.selectedArea.dragControls) {
    this.selectedArea.dragControls.dispose();
    this.selectedArea.midPointDragControls.dispose();
  }
  this.selectedArea.dragControls = new DragControls(
    this.selectedArea.points,
    this.camera,
    this.renderer.domElement
  );
  this.selectedArea.midPointDragControls = new DragControls(
    this.selectedArea.lines.map((line) => line.midPoint),
    this.camera,
    this.renderer.domElement
  );
  this.selectedArea.dragControls.addEventListener("drag", this.dragAreaPoint);
  this.selectedArea.dragControls.addEventListener(
    "dragstart",
    this.dragStarted
  );
  this.selectedArea.dragControls.addEventListener("dragend", this.dragEnded);
  this.selectedArea.midPointDragControls.addEventListener(
    "drag",
    this.dragAreaMidPoint
  );
  this.selectedArea.midPointDragControls.addEventListener(
    "dragstart",
    this.dragStarted
  );
  this.selectedArea.midPointDragControls.addEventListener(
    "dragend",
    this.dragMidPointEnded
  );
};

export const disablePointDragMode = function () {
  if (this.selectedArea && this.selectedArea.dragControls) {
    this.selectedArea.dragControls.deactivate();
    this.selectedArea.midPointDragControls.deactivate();

    this.selectedArea.dragControls.removeEventListener(
      "drag",
      this.dragAreaPoint
    );
    this.selectedArea.dragControls.removeEventListener(
      "dragstart",
      this.dragStarted
    );
    this.selectedArea.dragControls.removeEventListener(
      "dragend",
      this.dragEnded
    );

    this.selectedArea.midPointDragControls.removeEventListener(
      "drag",
      this.dragAreaMidPoint
    );
    this.selectedArea.midPointDragControls.removeEventListener(
      "dragstart",
      this.dragStarted
    );
    this.selectedArea.midPointDragControls.removeEventListener(
      "dragend",
      this.dragMidPointEnded
    );
  }
};

export const calculateAreaAngle = function (geometry, material) {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  const cube = new THREE.Mesh(geometry, material);

  scene.add(cube);
  camera.position.z = 5;

  const faceIndex = 0;

  const normal = new THREE.Vector3();
  const a = new THREE.Vector3();
  const b = new THREE.Vector3();
  const c = new THREE.Vector3();

  const positionAttribute = geometry.getAttribute("position");
  const indexAttribute = geometry.getIndex();

  a.fromBufferAttribute(positionAttribute, indexAttribute.array[faceIndex * 3]);
  b.fromBufferAttribute(
    positionAttribute,
    indexAttribute.array[faceIndex * 3 + 1]
  );
  c.fromBufferAttribute(
    positionAttribute,
    indexAttribute.array[faceIndex * 3 + 2]
  );

  normal.subVectors(c, b).cross(b.sub(a)).normalize();

  const referenceVector = new THREE.Vector3(0, 1, 0);

  const angle = normal.angleTo(referenceVector);

  const angleInRadians =
    normal.dot(referenceVector) < 0 ? Math.PI - angle : angle;

  const angleInDegrees = angleInRadians * (180 / Math.PI);

  const roundedAngleInDegrees = Math.round(angleInDegrees);

  return roundedAngleInDegrees;
};

export const removePoints = function (points) {
  for (let point of points) {
    const object = this.scene.getObjectById(point.id);
    this.scene.remove(object);
  }
};

export const detectAreaIntersection = function () {
  const solarObjects = [];
  const filteredAreas = this.areas.filter(
    (area) => area.plane && area.plane.visible
  );
  for (let area of filteredAreas) {
    solarObjects.push(area.plane);
    solarObjects.push(...area.points);
  }

  let intersects = this.raycaster.intersectObjects(solarObjects);
  if (intersects.length > 0) return true;
  return false;
};

export const isAreaInFrontOfModel = function (areaDragged = false) {
  if (areaDragged) return true;

  const solarObjects = [this.modelObject.children[0]];
  const filteredAreas = this.areas.filter(
    (area) => area.plane && area.plane.visible
  );
  for (let area of filteredAreas) {
    solarObjects.push(area.plane);
    solarObjects.push(...area.points);
  }

  let intersects = this.raycaster.intersectObjects(solarObjects);
  if (intersects.length > 1) {
    const model = intersects.find(
      (object) => object.object.uuid === this.modelObject.children[0].uuid
    );
    const area = intersects.find(
      (object) => object.object.uuid !== this.modelObject.children[0].uuid
    );

    if (!model || !area) return true;

    const modelDistance = model.distance;
    const areaDistance = area.distance;

    if (Math.abs(areaDistance - modelDistance) < 1) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

export const dragAreaPoint = function (event) {
  this.dragOn = true;
  const draggedPoint = event.object;

  const mergedPoint = this.checkMergePoints(draggedPoint, false);
  this.reDrawAreaFromPoint(mergedPoint);

  if (draggedPoint.uuid !== mergedPoint.uuid) {
    let area = this.getAreaFromPoint(mergedPoint);

    area.plane.material.opacity = 0.5;
    this.removeSolarGroupPanels(area);
    this.populateArea(area);
    this.unHoldSolarArea(area);
  }

  setTimeout(() => (this.dragOn = false));
};

export const dragAreaMidPoint = function (e) {
  this.dragOn = true;

  const draggedPoint = e.object;
  let otherPoint = null;

  const lines = this.selectedArea.lines;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const pointGroup = line.midPoint;
    const [centerDot, wrapper] = pointGroup.children;
    if (draggedPoint.uuid === centerDot.uuid) {
      otherPoint = wrapper;
    } else if (draggedPoint.uuid === wrapper.uuid) {
      otherPoint = centerDot;
    }
    if (otherPoint) {
      otherPoint.position.x = draggedPoint.position.x;
      otherPoint.position.y = draggedPoint.position.y;
      otherPoint.position.z = draggedPoint.position.z;

      if (!this.selectedArea.midPointAdded) {
        line.line.visible = false;

        for (let j = 0; j < this.selectedArea.points.length; j++) {
          const point = this.selectedArea.points[j];
          if (point.uuid === line.firstPoint.uuid) {
            const newPointIndex = j + 1;
            this.selectedArea.points.splice(newPointIndex, 0, draggedPoint);
            this.selectedArea.midPointAdded = true;
            break;
          }
        }

        this.selectedArea.lineIndexToRemove = i;

        const firstLine = this.createLineGroup(
          line.firstPoint,
          draggedPoint,
          false,
          false,
          true
        );
        const secondLine = this.createLineGroup(
          draggedPoint,
          line.secondPoint,
          false,
          false,
          true
        );

        this.selectedArea.lines.splice(i + 1, 0, firstLine, secondLine);
      } else {
        this.reDrawAreaFromPoint(draggedPoint);
      }
      setTimeout(() => (this.dragOn = false));
      return;
    }
  }
  setTimeout(() => (this.dragOn = false));
};

export const reDrawAreaFromPoint = function (point) {
  let selectedArea = null;
  for (let area of this.areas) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === point.uuid) {
        selectedArea = area;
        break;
      }
    }
  }

  for (let i = 0; i < selectedArea.points.length; i++) {
    if (selectedArea.points[i].uuid === point.uuid)
      selectedArea.points[i] = point;
  }

  const projectedPoints = this.projectPointsOnPlane(
    selectedArea.points,
    selectedArea.trianglePlane
  );

  const vertices = new Float32Array(projectedPoints);

  const triangleIndices = this.getTriangleIndices(
    selectedArea.points,
    this.getAxisDifferences(selectedArea.points.map((point) => point.position))
  );
  const indices = [].concat(...triangleIndices);

  selectedArea.plane.geometry.setAttribute(
    "position",
    new THREE.BufferAttribute(vertices, 3)
  );
  selectedArea.plane.geometry.setIndex(
    new THREE.Uint16BufferAttribute(indices, 1)
  );
  selectedArea.plane.geometry.computeBoundingBox();
  selectedArea.plane.geometry.computeBoundingSphere();

  selectedArea.indices = indices;

  const linesToUpdate = selectedArea.lines.filter(
    (line) =>
      line.firstPoint.uuid === point.uuid ||
      line.secondPoint.uuid === point.uuid
  );

  for (let line of linesToUpdate) {
    if (line.firstPoint.uuid === point.uuid) line.firstPoint = point;
    else line.secondPoint = point;

    const firstPoint = new THREE.Vector3();
    const secondPoint = new THREE.Vector3();
    line.firstPoint.getWorldPosition(firstPoint);
    line.secondPoint.getWorldPosition(secondPoint);
    let points = [firstPoint, secondPoint];
    this.updateLinePosition(line.line, points);

    const midPoint = line.midPoint;
    this.updateMidPointPosition(midPoint, firstPoint, secondPoint);

    const cameraPosition = this.camera.position.clone();
    const pointPosition = point.position.clone();
    const rayDirection = pointPosition.sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, rayDirection);

    const intersects = this.raycaster.intersectObject(
      this.modelObject.children[0]
    );

    if (intersects.length > 0) {
      point.prevPosition = {
        x: point.position.x,
        y: point.position.y,
        z: point.position.z,
      };
    }
  }

  return selectedArea;
};

// Function to check if two line segments intersect
const doLinesIntersect = function (p1, p2, p3, p4) {
  const d1 = new THREE.Vector2(p2.x - p1.x, p2.y - p1.y);
  const d2 = new THREE.Vector2(p4.x - p3.x, p4.y - p3.y);
  const d3 = new THREE.Vector2(p1.x - p3.x, p1.y - p3.y);

  const denom = d1.x * d2.y - d2.x * d1.y;
  if (denom === 0) return false; // Lines are parallel

  const ua = (d2.x * d3.y - d2.y * d3.x) / denom;
  const ub = (d1.x * d3.y - d1.y * d3.x) / denom;

  return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
};

// Function to check if the restricted area is contained within the area
export const isRestrictedAreaContained = function (
  areaPlane,
  restrictedAreaPlane
) {
  // Get the vertices of the area and restricted area planes
  const areaVertices = areaPlane.geometry.attributes.position.array;
  const restrictedVertices =
    restrictedAreaPlane.geometry.attributes.position.array;

  // Create a Box3 for the area plane
  const areaBox = new THREE.Box3().setFromObject(areaPlane);

  // Check each vertex of the restricted area
  for (let i = 0; i < restrictedVertices.length; i += 3) {
    const vertex = new THREE.Vector3(
      restrictedVertices[i],
      restrictedVertices[i + 1],
      restrictedVertices[i + 2]
    );

    // Transform the vertex to world coordinates
    vertex.applyMatrix4(restrictedAreaPlane.matrixWorld);

    // Check if the vertex is inside the area box
    if (!areaBox.containsPoint(vertex)) {
      return false; // If any vertex is outside, return false
    }
  }

  // Check for intersections between edges of area and restricted area
  const areaEdges = [];
  const restrictedEdges = [];

  // Extract edges from area plane
  for (let i = 0; i < areaVertices.length; i += 3) {
    const p1 = new THREE.Vector3(
      areaVertices[i],
      areaVertices[i + 1],
      areaVertices[i + 2]
    );
    const p2 = new THREE.Vector3(
      areaVertices[(i + 3) % areaVertices.length],
      areaVertices[(i + 4) % areaVertices.length],
      areaVertices[(i + 5) % areaVertices.length]
    );
    areaEdges.push({ p1, p2 });
  }

  // Extract edges from restricted area plane
  for (let i = 0; i < restrictedVertices.length; i += 3) {
    const p1 = new THREE.Vector3(
      restrictedVertices[i],
      restrictedVertices[i + 1],
      restrictedVertices[i + 2]
    );
    const p2 = new THREE.Vector3(
      restrictedVertices[(i + 3) % restrictedVertices.length],
      restrictedVertices[(i + 4) % restrictedVertices.length],
      restrictedVertices[(i + 5) % restrictedVertices.length]
    );
    restrictedEdges.push({ p1, p2 });
  }

  // Check for intersections between area edges and restricted edges
  for (const areaEdge of areaEdges) {
    for (const restrictedEdge of restrictedEdges) {
      if (
        doLinesIntersect(
          areaEdge.p1,
          areaEdge.p2,
          restrictedEdge.p1,
          restrictedEdge.p2
        )
      ) {
        return false; // If any edge intersects, return false
      }
    }
  }

  return true; // All vertices are contained and no edges intersect
};

export const calculateSurfaceArea = function (area) {
  if (!area.closed) return 0;

  const points = area.points;
  const areaVertices = [];
  for (let i = 0; i < points.length; i++) {
    areaVertices.push(
      new THREE.Vector3(
        points[i].position.x,
        points[i].position.y,
        points[i].position.z
      )
    );
  }

  let surfaceArea = 0;

  const triangles = [];
  for (let i = 0; i < area.indices.length; i++) {
    if (triangles.length > 0 && triangles[triangles.length - 1].length < 3) {
      triangles[triangles.length - 1].push(areaVertices[area.indices[i]]);
    } else {
      triangles.push([areaVertices[area.indices[i]]]);
    }
  }

  // Triangulate the points to form individual triangles
  for (let triangle of triangles) {
    const [v1, v2, v3] = triangle;

    // Calculate the area of the triangle formed by the vertices
    const triangleArea = this.calculateTriangleArea(v1, v2, v3);

    // Add the triangle area to the total surface area
    surfaceArea += triangleArea;
  }

  return surfaceArea.toFixed(2);
};

export const detectSolarAreaIntersection = function (event) {
  this.setMousePosition(event);

  const solarObjects = [];
  this.areas
    .filter((area) => area.closed)
    .forEach((area) => {
      solarObjects.push(area.plane);
      area.points.forEach((point) => {
        solarObjects.push(point);
      });
      area.lines.forEach((line) => {
        solarObjects.push(line.midPoint);
        solarObjects.push(line.line);
      });
    });

  let intersects = this.raycaster.intersectObjects(solarObjects);

  if (intersects.length > 0) {
    this.changeCursorToPointer();
  } else {
    if (this.selectedArea) this.restoreDefaultCursor();
    else this.changeCursorToCrosshair();
  }
};

export const detectSolarAreaPointsIntersection = function (event) {
  if (!this.selectedArea) return;
  this.setMousePosition(event);

  const solarObjects = [];
  this.selectedArea.points.forEach((point) => {
    solarObjects.push(point);
  });
  this.selectedArea.lines.forEach((line) => {
    solarObjects.push(line.midPoint);
  });

  this.selectedArea.restrictedAreas
    .filter((restrictedArea) => restrictedArea.closed)
    .forEach((restrictedArea) => {
      restrictedArea.points.forEach((point) => {
        solarObjects.push(point);
      });
    });

  let intersects = this.raycaster.intersectObjects(solarObjects);

  if (intersects.length > 0) {
    this.changeCursorToPointer();
  } else {
    this.restoreDefaultCursor();
  }
};

export const removeLastPoint = function (event) {
  const key = event.key;
  if (key !== "Backspace" && key !== "Delete") return;

  if (this.areas.length === 0) return;
  const currentArea = this.areas[this.areas.length - 1];
  if (currentArea.points.length === 0 || currentArea.closed) return;
  if (currentArea.points.length === 1) {
    this.removeUnfinshedArea();
  } else {
    this.undo();
  }
};

export const getAreaFromPoint = function (point) {
  for (let area of this.areas) {
    for (let i = 0; i < area.points.length; i++) {
      let areaPoint = area.points[i];
      if (areaPoint.uuid === point.uuid) return area;
    }
  }
};

export const createUpdateSolarGroup = async function (area) {
  if (this.sample || this.anonymousUser) return;
  const solarGroup = {
    projectId: Number(this.projectId),
    position: area.points.map((point) => point.position),
    panelId: area.panelId,
    horizontal_panel_spacing: area.horizontalSpacing,
    vertical_panel_spacing: area.verticalSpacing,
    margin: area.margin,
    offset: area.offset,
    orientation: area.orientation ? "vertical" : "horizontal",
    initial_panel_position: area.startPosition || null,
    original_panel_position: area.originalPosition,
    panels: area.instancedMesh ? area.instancedMesh.instancesCount : 0,
    iteration: area.iteration,
  };
  if (area.id) {
    solarGroup.id = area.id;
    if (area.currentRotation)
      solarGroup.panel_rotation = area.currentRotation.toFixed(3);
    return await API.airteam3DViewer.updateSolarGroupObject(solarGroup);
  }
  const response = await API.airteam3DViewer.createSolarGroupObject(solarGroup);
  area.id = response.data;
};

export const convertUserDefaultValuesFromResponse = (data) => {
  return {
    horizontalSpacing: convertToCentimeters(data.horizontal_spacing),
    verticalSpacing: convertToCentimeters(data.vertical_spacing),
    margin: convertToCentimeters(data.panel_margin),
    offset: convertToCentimeters(data.offset),
    orientation: data.orientation === "vertical",
    panelId: data.panel_id,
  };
};

export const getUserDefaultSolarValues = async function () {
  const response = await API.airteam3DViewer.getDefaultValues();
  this.solarDefaultValues = convertUserDefaultValuesFromResponse(response.data);
};

export const getUserLikedPanel = async function () {
  const response = await API.airteam3DViewer.getLikedPanel();
  if (response.data?.panelId) {
    this.likedPanelId = response.data?.panelId;
  }
};

export const useDefaultSolarValues = function (area) {
  area.horizontalSpacing = DEFAULT_HORIZONTAL_SPACING;
  area.verticalSpacing = DEFAULT_VERTICAL_SPACING;
  area.panelId = this.horizontalPanelTypes[0].id;
  area.margin = DEFAULT_MARGIN;
  area.offset = DEFAULT_OFFSET;
  area.orientation = true;
};

export const useStoredSolarValues = function (area, storedArea) {
  area.horizontalSpacing = storedArea.horizontal_panel_spacing;
  area.verticalSpacing = storedArea.vertical_panel_spacing;
  area.panelId = storedArea.panelId;
  area.margin = storedArea.margin;
  area.offset = storedArea.offset;
  area.orientation = storedArea.orientation === "vertical";
  area.iteration = storedArea.iteration;
  if (storedArea.initial_panel_position) {
    area.startPosition = roundVector(storedArea.initial_panel_position);
  }
  if (storedArea.original_panel_position) {
    area.originalPosition = roundVector(storedArea.original_panel_position);
  } else if (storedArea.initial_panel_position) {
    area.originalPosition = roundVector(storedArea.initial_panel_position);
  }
  if (storedArea.panel_rotation) {
    area.currentRotation = parseFloat(storedArea.panel_rotation);
  }
};

export const isAngleInRange = function (angle) {
  if (angle > this.upperAngleRange) return false;
  if (angle < this.lowerAngleRange) return false;
  return true;
};

export const setAngleInRange = function (angle) {
  if (angle > this.upperAngleRange) return this.upperAngleRange;
  if (angle < this.lowerAngleRange) return this.lowerAngleRange;
  return angle;
};

export const checkPanelNumberAndVisibility = function (selectedArea) {
  if (selectedArea && selectedArea.moveGridLabel) {
    if (
      selectedArea.instancedMesh &&
      selectedArea.instancedMesh.visibilityArray.some(
        (visibility) => visibility === true
      )
    ) {
      selectedArea.moveGridLabel.visible = true;
      selectedArea.rotateGridLabel.visible = true;
    } else {
      selectedArea.moveGridLabel.visible = false;
      selectedArea.rotateGridLabel.visible = false;
    }
  }
};

export const controlSpacingBetweenLabels = function () {
  const moveGridLabel = this.selectedArea.moveGridLabel;
  const rotateGridLabel = this.selectedArea.rotateGridLabel;

  if (!moveGridLabel || !rotateGridLabel) return;

  const centerPoint = this.getCenterPointFromVectors(
    this.selectedArea.innerPlane.points
  );

  const cameraDistance = this.camera.position.distanceTo(centerPoint);
  let zoomFactor = Math.max(1, cameraDistance / 15);

  if (cameraDistance < 16) {
    zoomFactor = 1;
  }

  moveGridLabel.labelOffset = this.selectedArea.horizontalVector
    .clone()
    .multiplyScalar(-0.4 * zoomFactor);

  const moveLabelPosition = new THREE.Vector3()
    .copy(centerPoint)
    .add(moveGridLabel.labelOffset);
  moveGridLabel.position.set(
    moveLabelPosition.x,
    moveLabelPosition.y,
    moveLabelPosition.z
  );

  rotateGridLabel.labelOffset = this.selectedArea.horizontalVector
    .clone()
    .multiplyScalar(0.4 * zoomFactor);
  const rotateLabelPosition = new THREE.Vector3()
    .copy(moveGridLabel.position)
    .add(rotateGridLabel.labelOffset)
    .add(rotateGridLabel.labelOffset);
  rotateGridLabel.position.set(
    rotateLabelPosition.x,
    rotateLabelPosition.y,
    rotateLabelPosition.z
  );
};

export const getAreaPanelsCount = function (area) {
  if (area.type === "AREA") return area.panels.length || 0;
  if (!area.instancedMesh) return 0;
  return area.instancedMesh.visibilityArray.filter((v) => v === true).length;
};
