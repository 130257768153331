import Request from "../Request";

const FFSOLAR_SERVER_URL = window.runtimeConfig?.FFSOLAR_SERVER_URL;

const airteam3DViewer = {
  getModelPreview(modelId) {
    return Request.get(
      `/project/airteam-viewer/api/v1/viewer-model-preview/${modelId}/`
    );
  },
  getFfSolarObjectsByProjectId(projectId) {
    return Request.get(
      `${FFSOLAR_SERVER_URL}/ffsolarobject?projectId=${projectId}`
    );
  },
  createObject(data) {
    return Request.postWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject`,
      data
    );
  },
  patchObject(id, show) {
    return Request.patchWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/${id}`,
      {
        show: show,
      }
    );
  },

  patchRestrictedAreaObject(data, id) {
    return Request.patchWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/${id}`,
      data
    );
  },
  updateObject(data) {
    return Request.putWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject`,
      data
    );
  },
  deleteObject(id) {
    return Request.deleteWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/${id}`
    );
  },
  deleteBulkPanelObjects(data) {
    return Request.deleteWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/bulk`,
      data
    );
  },
  getCustomPanels(projectId) {
    let url = `${FFSOLAR_SERVER_URL}/custompaneltype`;
    if (projectId) {
      url += `?projectId=${projectId}`;
    }
    return Request.getWithAccessToken(url);
  },
  createCustomPanel(data) {
    return Request.postWithAccessToken(
      `${FFSOLAR_SERVER_URL}/custompaneltype`,
      data
    );
  },
  updateCustomPanel(data) {
    return Request.patchWithAccessToken(
      `${FFSOLAR_SERVER_URL}/custompaneltype`,
      data
    );
  },
  deleteCustomPanel(panelId) {
    return Request.deleteWithAccessToken(
      `${FFSOLAR_SERVER_URL}/custompaneltype`,
      { id: panelId, is_deleted: true }
    );
  },
  updateLikedPanel(data) {
    return Request.postWithAccessToken(
      `${FFSOLAR_SERVER_URL}/likedpanel`,
      data
    );
  },
  unlikePanel() {
    return Request.deleteWithAccessToken(`${FFSOLAR_SERVER_URL}/likedpanel`);
  },
  getDefaultPanelTypes() {
    return Request.get(`${FFSOLAR_SERVER_URL}/paneltype`);
  },
  createPanelObject(data) {
    return this.createObject({ type: "PANEL", ...data });
  },
  createBulkPanelObjects(array) {
    return Request.postWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/bulk`,
      array
    );
  },
  createAreaObject(data) {
    return this.createObject({ type: "AREA", ...data });
  },
  createMeasurementObject(data) {
    return this.createObject({ type: "MEASUREMENT", ...data });
  },
  createMeasurementAreaObject(data) {
    return this.createObject({ type: "AREA_MEASUREMENT", ...data });
  },
  createSolarGroupObject(data) {
    return this.createObject({ type: "SOLAR_GROUP", ...data });
  },
  updateAreaObject(data) {
    return this.updateObject({ type: "AREA", ...data });
  },
  updateMeasurementObject(data) {
    return this.updateObject({ type: "MEASUREMENT", ...data });
  },
  updateMeasurementAreaObject(data) {
    return this.updateObject({ type: "AREA_MEASUREMENT", ...data });
  },
  updatePanelObject(data) {
    return this.updateObject({ type: "PANEL", ...data });
  },
  updateSolarGroupObject(data) {
    return this.updateObject({ type: "SOLAR_GROUP", ...data });
  },
  patchSolarGroupObject(id, data) {
    return Request.patchWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/${id}`,
      data
    );
  },
  updateBulkPanelObject(array) {
    return Request.putWithAccessToken(
      `${FFSOLAR_SERVER_URL}/ffsolarobject/bulk`,
      array
    );
  },
  getDefaultValues() {
    return Request.getWithAccessToken(`${FFSOLAR_SERVER_URL}/defaults`);
  },
  getLikedPanel() {
    return Request.getWithAccessToken(`${FFSOLAR_SERVER_URL}/likedpanel`);
  },
  updateDefaultValues(data) {
    return Request.putWithAccessToken(`${FFSOLAR_SERVER_URL}/defaults`, data);
  },

  getProjectConfig(id) {
    return Request.getWithAccessToken(
      `${FFSOLAR_SERVER_URL}/projects/${id}/config`
    );
  },
  putProjectConfig(id, data) {
    if (!data) {
      console.error("Payload data is missing or undefined");
      return;
    }

    return Request.putWithAccessToken(
      `${FFSOLAR_SERVER_URL}/projects/${id}/config`,
      data
    );
  },
};

export default airteam3DViewer;
